import { useEffect, useState } from 'react'
import {
  faAngular,
  faPython,
  faGitAlt,
  faWindows,
  faNode,
  faReact,
  faHtml5,
  faPhp,
  faRocketchat,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters/animatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './portfolio.scss'

const Portfolio = () => {
  const { i18n, t } = useTranslation()
  const [letterClass, setLetterClass] = useState('text-animate')
  const projects = t('portfolio.projects', { returnObjects: true })

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  const Icon = (props) => {
    console.log(props.icon)
    switch (props.icon) {
      case 'faAngular':
        return <FontAwesomeIcon icon={faAngular} color={props.color} />
      case 'faReact':
        return <FontAwesomeIcon icon={faReact} color={props.color} />
      case 'faPhp':
        return <FontAwesomeIcon icon={faPhp} color={props.color} />
      case 'faRocketchat':
        return <FontAwesomeIcon icon={faRocketchat} color={props.color} />
      case 'faHtml5':
        return <FontAwesomeIcon icon={faHtml5} color={props.color} />
      default:
        return <FontAwesomeIcon icon={faGithub} color="#c5d1de" />
    }
  }

  return (
    <>
      <div className="container portfolio-page">
        <div className="text-zone">
          <h1>
            {i18n.resolvedLanguage === 'fr' ? (
              <AnimatedLetters letterClass={letterClass} strArray={['P', 'r', 'o', 'j', 'e', 't', 's']} idx={15} />
            ) : (
              <AnimatedLetters letterClass={letterClass} strArray={['P', 'r', 'o', 'j', 'e', 'c', 't', 's']} idx={15} />
            )}
          </h1>
          <div className="project-grid">
            {projects.map((project) => (
              <a className="project" href={project.githubLink} target="_blank" rel="noreferrer">
                <h3>
                  {t(project.title)} <Icon icon={project.icon} color={project.color} />
                </h3>
                <p>{t(project.description)}</p>
                <span style={{ justifyContent: 'center', display: 'flex' }}>
                  {project.githubLink !== '#' ? <Icon /> : <p>{t('general.soon')}</p>}
                </span>
              </a>
            ))}
          </div>
        </div>
        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faAngular} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faWindows} color="#74C0FC" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faPython} color="#FFD43B" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faNode} color="#63E6BE" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Portfolio
