import consts from '../../consts.scss'
import LogoM from '../../assets/images/logoM.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faHome, faUser, faEnvelope, faSuitcase, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './sidebar.scss'

const SideBarAdditionnal = (props) => {
  return (
    <div className={'side-bar-additionnal-' + props.resolution}>
      <ul>
        {props.resolution === 'xs' ? (
          <li>
            <button onClick={() => props.i18n.changeLanguage(props.language)}>
              <FontAwesomeIcon icon={faLanguage} color={consts.tertiaryColor} size="2x" className="anchor-icon" />
            </button>
          </li>
        ) : (
          <></>
        )}

        <li>
          <a href="https://www.linkedin.com/in/mehdi-barchi/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faLinkedin} color={consts.tertiaryColor} size="2x" className="anchor-icon" />
          </a>
        </li>
        <li>
          <a href="https://github.com/Git-Sathys" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faGithub} color={consts.tertiaryColor} size="2x" className="anchor-icon" />
          </a>
        </li>
      </ul>
    </div>
  )
}

const Sidebar = () => {
  const { i18n } = useTranslation()
  function getNextLanguage() {
    if (i18n.resolvedLanguage === 'fr') {
      return 'en'
    }
    return 'fr'
  }
  return (
    <>
      <div className="nav-bar-xl">
        <Link className="logo" to="/">
          <img src={LogoM} alt="Logo M" />
        </Link>
        <div className="language">
          <button onClick={() => i18n.changeLanguage(getNextLanguage())}>
            <FontAwesomeIcon icon={faLanguage} color={consts.tertiaryColor} size="2x" className="anchor-icon" />
          </button>
        </div>
        <nav>
          <NavLink exact="true" activeclassname="active" to="/">
            <FontAwesomeIcon icon={faHome} color={consts.tertiaryColor} />
          </NavLink>
          <NavLink activeclassname="active" className="about-link" to="/about">
            <FontAwesomeIcon icon={faUser} color={consts.tertiaryColor} />
          </NavLink>
          <NavLink activeclassname="active" className="portfolio-link" to="/portfolio">
            <FontAwesomeIcon icon={faSuitcase} color={consts.tertiaryColor} />
          </NavLink>
          <NavLink activeclassname="active" className="contact-link" to="/contact">
            <FontAwesomeIcon icon={faEnvelope} color={consts.tertiaryColor} />
          </NavLink>
        </nav>
        <SideBarAdditionnal resolution="xl"></SideBarAdditionnal>
      </div>
      <div className="nav-bar-xs">
        <SideBarAdditionnal resolution="xs" language={getNextLanguage()} i18n={i18n}></SideBarAdditionnal>
        <nav>
          <NavLink exact="true" activeclassname="active" to="/">
            <FontAwesomeIcon icon={faHome} color={consts.tertiaryColor} />
          </NavLink>
          <NavLink activeclassname="active" className="about-link" to="/about">
            <FontAwesomeIcon icon={faUser} color={consts.tertiaryColor} />
          </NavLink>
          <NavLink activeclassname="active" className="portfolio-link" to="/portfolio" content="test">
            <FontAwesomeIcon icon={faSuitcase} color={consts.tertiaryColor} />
          </NavLink>
          <NavLink activeclassname="active" className="contact-link" to="/contact">
            <FontAwesomeIcon icon={faEnvelope} color={consts.tertiaryColor} />
          </NavLink>
        </nav>
      </div>
    </>
  )
}

export default Sidebar
