import { Outlet } from 'react-router-dom'
import React from 'react'
import Sidebar from '../Sidebar/sidebar'
import { useTranslation } from 'react-i18next'
import './layout.scss'

const Layout = (language, options) => {
  const { t } = useTranslation()
  const [width, setWidth] = React.useState(window.innerWidth)
  const breakpoint = 600
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResizeWindow)
    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
  }, [])

  if (width > breakpoint) {
    return (
      <div className="App">
        <Sidebar />
        <div className="page">
          <span className="tags top-tags">&lt; {t('general.welcome')} &gt;</span>
          <Outlet />
        </div>
      </div>
    )
  } else {
    return (
      <div className="App">
        <div className="page">
          <span className="tags top-tags">&lt; {t('general.welcome')} &gt;</span>
          <Outlet />
        </div>
        <Sidebar />
      </div>
    )
  }
}

export default Layout
