import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters/animatedLetters'
import { useTranslation } from 'react-i18next'
import Logo from './Logo/logo'
import './home.scss'

const Home = () => {
  const { t, i18n } = useTranslation()
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = ['M', 'e', 'h', 'd', 'i', '', 'B', 'a', 'r', 'c', 'h', 'i']
  const jobArrayEn = ['F', 'u', 'l', 'l', '', 'S', 't', 'a', 'c', 'k', '', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r']
  const jobArrayFr = ['D', 'é', 'v', 'e', 'l', 'o', 'p', 'p', 'e', 'u', 'r', '', 'F', 'u', 'l', 'l', '', 'S', 't', 'a', 'c', 'k']

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={i18n.resolvedLanguage === 'fr' ? jobArrayFr : jobArrayEn}
              idx={16}
            />
          </h1>
          <h2 className="description">{t('home.description')}</h2>
          <Link to="/contact" className="flat-button">
            {t('home.contact')}
          </Link>
          <a
            href="https://mehdibarchi.fr/CV_Mehdi_Barchi.pdf"
            target="_blank"
            rel="noreferrer"
            className="flat-button-v2 flat-button"
          >
            {t('home.resume')}
          </a>
        </div>
        <Logo />
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Home
