import { useEffect } from 'react'
import { motion } from 'framer-motion'
import './logo.scss'

const Logo = () => {
  useEffect(() => {}, [])

  return (
    <div className="logo-container">
      <div
        className="logo"
        style={{
          border: 'none',
          padding: '0',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <motion.svg viewBox="0 0 154 158" xmlns="http://www.w3.org/2000/svg">
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{
              duration: 10,
              ease: 'easeInOut',
            }}
            strokeWidth={4}
            strokeDasharray="0 1"
            fill="none"
            d="M154.59,4.47c0-.94-.24-1.21-1.17-1.21-2.34.02-4.68-.13-7.02-.12-7.54.03-15.08.19-22.62.09-1.68-.02-2.78.52-3.93,1.55-5.79,5.16-11.64,10.26-17.42,15.45-.84.74-1.2.65-2-.06-7.05-6.32-14.16-12.57-21.24-18.85-1.99-1.76-1.96-1.77-3.99.04-7.11,6.34-14.25,12.65-21.34,19.01-.78.7-1.24.66-1.94-.02-1.78-1.7-3.58-3.38-5.43-5.02-4.26-3.79-8.54-7.55-12.82-11.32-.64-.56-1.3-.94-2.25-.93-9.94.09-19.89.17-29.83.16-1.31-.01-1.59.31-1.59,1.58.04,19.18.02,38.36.01,57.54,0,.26.02.52,0,.78-.04.69.17,1.08.97,1.07,4.29-.03,8.59-.03,12.88-.02.6,0,.58-.33.51-.75,0-14.95,0-29.9.01-44.84.09-.28.29-.42.57-.42,3.9,0,7.8.03,11.7,0,.82,0,1.32.45,1.85.92,3.55,3.13,7.1,6.27,10.65,9.39,8.03,7.05,16.06,14.1,24.1,21.15,4.33,3.8,8.68,7.6,13,11.42.63.56.91.59,1.61-.1,2.4-2.36,4.94-4.58,7.49-6.8,7.29-6.33,14.61-12.62,21.9-18.96,6.3-5.47,12.57-10.97,18.83-16.49.57-.5,1.14-.52,1.79-.52,3.32,0,6.64.18,9.94-.06,2.11-.16,2.33.65,2.32,2.42-.07,8.71.01,17.42-.03,26.13,0,1.2.18,1.69,1.54,1.66,3.96-.1,7.93-.11,11.89-.02,1.25.03.99-.67.99-1.33.02-14.17.02-28.34.07-42.52ZM89.22,31.54c-3.84,3.38-7.66,6.79-11.45,10.23-.55.5-.81.52-1.37.01-3.79-3.44-7.61-6.85-11.46-10.22-.75-.67-.67-1.08.03-1.68,3.83-3.29,7.64-6.61,11.45-9.92.24-.2.46-.4.73-.64.44.32.83.55,1.16.84,3.67,3.28,7.3,6.61,11.02,9.83.87.76.52,1-.11,1.55Z
            M43.76,157.07c0,1.14-.27,1.57-1.51,1.57-13.38-.06-26.77-.09-40.15-.03-1.82.01-2.1-.65-2.09-2.24.05-20.79.05-41.59.06-62.38v-1.76c-.02-3.43-.02-3.44,3.38-3.41,3.38.03,6.76.07,10.14.11.27,0,.66-.21.76.29-.04.04-.12.09-.11.12.03.08.09.16.14.25v1.19c.02,13.13.05,26.26.06,39.39.01,3.77-.03,7.54-.04,11.31v.79s-.14.09-.14.11c.03.09.09.18.13.27-.13,1.29.71,1.18,1.59,1.18,8.64-.01,17.29.04,25.93-.03,1.57-.02,1.95.47,1.9,1.96-.12,3.77-.1,7.54-.05,11.31Z
            M154.46,128.99v-38.21c.02-4.41.02-8.81.09-13.21.01-.93-.25-1.21-1.19-1.2-3.95.04-7.9.04-11.85-.03-1.23-.03-1.68.35-1.36,1.55-.02,3.44-.05,6.87-.05,10.3.03,12.69.07,25.39.1,38.09,0,.77-.01,1.54-.01,2.32,0,4.16,0,8.32-.01,12.48-.02.26-.12.53-.07.77.33,1.73-.41,2.09-2.06,2.02-3.42-.15-6.86-.05-10.29-.04-1.38,0-3.03.43-2.88-1.9.49-.18.37-.62.37-.98-.02-11.13-.04-22.26-.07-33.4.01-1.3.01-2.61.01-3.92.02-11.44.05-22.88.05-34.32.01-9.37-.02-18.73-.04-28.09.13-.94.2-1.87-.06-3.12-4.76,4.27-9.32,8.38-13.91,12.46-.52.47-.55,1.07-.47,1.6.52,3.44-.65,6.03-3.67,7.82-.6.35-1.06.94-1.6,1.41-5,4.33-10.02,8.63-14.98,12.99-4.25,3.74-8.45,7.55-12.65,11.35-.55.5-.81.48-1.38-.06-3.34-3.14-6.71-6.25-10.14-9.29-6.81-6.04-13.64-12.06-20.52-18.02-1.56-1.35-2.44-2.79-1.86-4.89.31-1.15.03-2.16-.89-2.97-4.5-3.95-9-7.91-13.63-11.99-.11.43-.18.59-.18.76,0,25.55,0,51.1-.01,76.65,0,.86.26,1.47.89,2.01,2.53,2.22,5.03,4.46,7.57,6.67,12.51,10.89,25.05,21.76,37.52,32.7,1.32,1.16,1.96,1.89,3.71.27,6.07-5.63,12.41-10.98,18.64-16.44,3.81-3.33,7.6-6.68,11.43-9.99.8-.69,1.89-1.63,1.86.6v24.97c-.07.12-.07.25,0,.37-.43,1.45.54,1.42,1.5,1.42,13.62.11,27.24.19,40.86,0,.99-.01,1.34-.25,1.33-1.29-.06-9.41-.07-18.81-.1-28.22ZM110.81,108.07c.01,1.32-.44,2.17-1.38,2.99-10.48,9.21-20.95,18.43-31.41,27.68-.68.6-1.07.66-1.83-.02-10.42-9.38-20.88-18.72-31.37-28.03-.9-.8-1.06-1.65-1.05-2.74.02-10.27.01-20.54.01-31.24,3.73,2.84,6.8,5.9,10.04,8.76,7.25,6.4,14.49,12.83,21.72,19.25,1.02.91,1.68,1.38,3.1.11,10.07-8.96,20.29-17.76,30.46-26.61.43-.37.9-.71,1.35-1.06.62.44.32,1,.32,1.46.01,9.82-.02,19.64.04,29.45Z"
          />
        </motion.svg>
      </div>
    </div>
  )
}

export default Logo
