import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters/animatedLetters'
import { faAngular, faGitAlt, faNode, faReact } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import './about.scss'

const About = () => {
  const { t, i18n } = useTranslation()
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            {i18n.resolvedLanguage === 'fr' ? (
              <AnimatedLetters letterClass={letterClass} strArray={['À', '', 'P', 'r', 'o', 'p', 'o', 's']} idx={15} />
            ) : (
              <AnimatedLetters letterClass={letterClass} strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']} idx={15} />
            )}
          </h1>
          <h2>{t('about.about.h2-1')}</h2>
          <p>{t('about.about.p-1')}</p>
          <p>{t('about.about.p-2')}</p>

          <h1 style={{ marginTop: '35px' }}>
            {i18n.resolvedLanguage === 'fr' ? (
              <AnimatedLetters letterClass={letterClass} strArray={['F', 'o', 'r', 'm', 'a', 't', 'i', 'o', 'n']} idx={19} />
            ) : (
              <AnimatedLetters letterClass={letterClass} strArray={['F', 'o', 'r', 'm', 'a', 't', 'i', 'o', 'n']} idx={19} />
            )}
          </h1>
          <h2>{t('about.formation.h2-1')}</h2>
          <p>{t('about.formation.p-1')}</p>
          <h2>{t('about.formation.h2-2')}</h2>
          <p>{t('about.formation.p-2')}</p>
          <h2>{t('about.formation.h2-3')}</h2>
          <p>{t('about.formation.p-3')}</p>
        </div>
        <div className="text-zone-2">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={
                i18n.resolvedLanguage === 'fr'
                  ? ['E', 'x', 'p', 'é', 'r', 'i', 'e', 'n', 'c', 'e', 's']
                  : ['E', 'x', 'p', 'e', 'r', 'i', 'e', 'n', 'c', 'e', 's']
              }
              idx={18}
            />
          </h1>
          <h2>
            {t('about.experience.h2-1')}
            <FontAwesomeIcon icon={faNode} color="#63E6BE" />
            <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
          </h2>
          <p align="LEFT">{t('about.experience.p-1')}</p>
          <h2>
            {t('about.experience.h2-2')}
            <FontAwesomeIcon icon={faNode} color="#63E6BE" />
            <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
          </h2>
          <p align="LEFT">{t('about.experience.p-2')}</p>
          <h2>
            {t('about.experience.h2-3')}
            <FontAwesomeIcon icon={faAngular} color="#DD0031" />
            <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
          </h2>
          <p align="LEFT">{t('about.experience.p-3')}</p>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
